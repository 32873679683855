import React from 'react';
import { Bar, LabelList, Cell } from 'recharts';
import layer from "../../assets/images/layers.svg";
import mapLayer from "../../assets/images/map.svg";
import headphone from "../../assets/images/headphones.svg";
import usericon from "../../assets/images/user-check.svg";
import users from "../../assets/images/users.svg";
import baseLineCompletedDidi from "../../assets/images/baseline-completed.svg";
import hamletCompleted from "../../assets/images/hamlet-survey-completed.svg";
import GlobalConfig from "../../config/globalconfig"
import { sortByKey } from '../../services/commonUtils';

export const baselineHelper = {
    defaultChart: {
        id: 1,
        name: "Survey progress",
        type: "surveyProgressSuperset",
        pdfType: 'surveyProgressSuperset',
        description: 'Progress of selection process across various stages',
    },
    defaultMap: {
        id: 1,
        name: "Focus Point Visualization",
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        pdfType: 'mapView',
        type: "map",
    },
    iconObj: {
        layer,
        mapLayer,
        headphone,
        users,
        usericon,
    },
    getboxData: (boxValues, role) => [{
        imgName: layer,
        name: "No. of Districts",
        values: role && ["National", "State"].includes(role)
        ? boxValues?.districtCount : '-'
    },
    {
        imgName: layer,
        name: "No. of Blocks",
        values: role && ["National", "State", "District"].includes(role)
        ? boxValues?.blockCount : '-',
    },
    {
        imgName: mapLayer,
        name: "No. of Villages",
        values: role && ["National", "State", "Block", "District"].includes(role)
        ? boxValues?.villageCount : '-'
    },
    {
        imgName: users,
        name: "No. of UPCMs",
        values: boxValues?.upcmCount,
    },
    {
        imgName: usericon,
        name: "No. of BPM approved didis",
        values: boxValues?.totalBPMApprovedDidiCount,
    },
    {
        imgName: baseLineCompletedDidi,
        name: "No. of didis with completed baseline survey.",
        values: boxValues?.baselineDidiCount,
    },
    {
        imgName: hamletCompleted,
        name: "No. of hamlets survey completed",
        values: boxValues?.hamletDidiCount,
    },
    {
        imgName: usericon,
        name: "Drop-Out",
        values: boxValues?.didiDropOutCount
      },
      {
        imgName: usericon,
        name: "No. of Didis covered (BPM approved didis - dropped)",
        values: boxValues?.coveredDidis
      },
],

}

// ******************For Bar Chart*******************

export const RenderCustomLabelBarChart = (props) => {
    const { x, y, width, value, toggleType } = props;
    const radius = 10;
    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#222E50" fontSize="14px" textAnchor="middle">
                {toggleType ? value : `${value}%`}
            </text>
        </g>
    )
}

export const getBarGraphType = (type, toggleType) => {
    switch (type) {
        case 'houseHold':
        case 'foodSecurity':
        case 'financialInclusion':
        case 'entitlementsDistribution':
        case 'socialInclusion':
        case 'livelihoodIncomeDistribution':
            return (
                <Bar barSize={90} dataKey="uv" fill="#A0B6FF">
                    <LabelList dataKey="uv" position="top" content={<RenderCustomLabelBarChart toggleType={toggleType} />} />
                </Bar>
            )



        default:
            break;
    }
}

export const CustomBarTooltipElement = ({ active, payload, label, type, toggleType }) => {


    if (active && payload && payload.length) {
        return (
            <div id="tooltip" className="right">
                <div className="tooltip-arrow" />
                <div className="tooltip-label">
                    {['castePwr', 'casteVo'].includes(type) && <div className='tooltip-heading'>{label}</div>}
                    <ul>
                        {
                            ['castePwr', 'casteVo'].includes(type) ? (
                                payload.map(entry => (
                                    <li style={{ listStyle: 'none' }}>
                                        <p className='tooltip-name'>{entry.name}</p>
                                        <span className='tooltip-value'>{toggleType ? entry.value : `${entry.value}%`}</span>
                                    </li>
                                ))
                            ) : (
                                <li style={{ listStyle: 'none' }}>
                                    <p className='tooltip-name'>{label}</p>
                                    <span className='tooltip-value'>{toggleType ? payload[0].value : `${payload[0].value}%`}</span>
                                </li>
                            )
                        }

                    </ul>


                </div>
            </div>
        );
    }

    return null;
};

export const XAxisBarProperties = (type) => {
    let angle = ['houseHold'].includes(type) ? 0 : 270;
    let dx = ['houseHold'].includes(type) ? 0 : -10;
    let dy = ['houseHold','foodSecurity', 'socialInclusion', 'financialInclusion', 'entitlementsDistribution'].includes(type) ? 0 : 90;

    return {
        angle: angle,
        dx: dx,
        dy: dy
    };
}


//   ******************For Waterfall Chart*******************

export const RenderCustomLabelWaterFall = (props) => {
    const { x, y, width, value, toggleType, name } = props;
    const radius = ['Rejected By BPM', 'Marked as Waitlisted By BPM', 'Pat Not Started', 'VO Not Started', 'Rejected by BPM', 'Rejected by VO', "BPM Approval Pending", 'Rejected By VO', "Kept As Waitlist", 'Rejected By PAT Exclusion', 'Rejected By PAT', 'Not Available'].includes(name) && value !== 0 ? -15 : 10;
    return (
        <g>
            <text x={x + width / 2} y={y - radius} fill="#222E50" fontSize="14px" textAnchor="end" >
                {toggleType ? value : `${value}%`}
            </text>
        </g>
    )
}

export const getWaterFallGraphType = (type, apiData, toggleType) => {
    switch (type) {
        case "funnel":
            return (
                <>
                    <Bar barSize={50} dataKey="pv" stackId="a" fill="transparent" />
                    <Bar barSize={50} dataKey="uv" stackId="a" isAnimationActive={false}>
                        <LabelList dataKey="uv" position="top" fill="#222E50" content={<RenderCustomLabelWaterFall toggleType={toggleType} />} />

                        {apiData?.map((item, index) => {
                            if (
                                [
                                    "Rejected By VO",
                                    "VO Not Started",
                                    "Rejected By BPM",
                                    "Kept As Waitlist",
                                ].includes(item.name) && item.uv !== 0
                            )
                                return <Cell key={index} fill="red" />;

                            if (item.name === "BPM Approval Pending")
                                return <Cell key={index} fill="gray" />;

                            if (item.name === "Added")
                                return <Cell key={index} fill="#4961B4" />;
                            return <Cell key={index} fill="#A0B6FF" />;
                        })}
                    </Bar>{" "}
                </>
            );

        case "pat":
            return (
                <>
                    <Bar barSize={50} dataKey="pv" stackId="a" fill="transparent" />
                    <Bar barSize={50} dataKey="uv" stackId="a">
                        <LabelList dataKey="uv" position="top" fill="#222E50" content={<RenderCustomLabelWaterFall toggleType={toggleType} />} />

                        {apiData?.map((item, index) => {

                            if (
                                [
                                    "Rejected By PAT",
                                    "Rejected By PAT Exclusion",
                                    'Pat Not Started',
                                    "Not Available",
                                ].includes(item.name) && item.uv !== 0
                            )
                                return <Cell key={index} fill="red" />;
                            if (item.name === "Total Poor Per PWR")
                                return <Cell key={index} fill="#4961B4" />;
                            return <Cell key={index} fill="#A0B6FF" />;
                        })}

                    </Bar>{" "}
                </>
            )
        case 'vo':
            return (
                <>
                    <Bar barSize={type === 'vo' ? 111 : 50} dataKey="pv" stackId="a" fill="transparent" />
                    <Bar barSize={type === 'vo' ? 111 : 50} dataKey="uv" stackId="a">
                        <LabelList dataKey="uv" position="top" fill="#222E50" content={<RenderCustomLabelWaterFall toggleType={toggleType} />} />

                        {apiData.map((item, index) => {
                            if (
                                [
                                    "Rejected By VO",
                                    "VO Not Started",
                                ].includes(item.name) && item.uv !== 0
                            )
                                return <Cell key={index} fill="red" />;

                            return <Cell key={index} fill="#A0B6FF" />;
                        })}
                    </Bar>{" "}
                </>
            )
        case 'bpm':
            return (
                <>
                    <Bar barSize={type === 'vo' ? 111 : 50} dataKey="pv" stackId="a" fill="transparent" />
                    <Bar barSize={type === 'vo' ? 111 : 50} dataKey="uv" stackId="a">
                        <LabelList dataKey="uv" position="top" fill="#222E50" content={<RenderCustomLabelWaterFall toggleType={toggleType} />} />

                        {apiData.map((item, index) => {
                            if (
                                [
                                    "Rejected By BPM",
                                    "Marked as Waitlisted By BPM"
                                ].includes(item.name) && item.uv !== 0
                            )
                                return <Cell key={index} fill="red" />;

                            if (item.name === "Endorsed By Vo")
                                return <Cell key={index} fill="#4961B4" />;
                            return <Cell key={index} fill="#A0B6FF" />;
                        })}
                    </Bar>{" "}
                </>
            )
        default:
            break;
    }
};

export const CustomWaterFallTooltipElement = ({ active, payload, label, type, toggleType }) => {

    if (active && payload && payload.length) {
        return (
            <div id="tooltip" className="right">
                <div className="tooltip-arrow" />
                <div className="tooltip-label">
                    {['castePwr', 'casteVo'].includes(type) && <div className='tooltip-heading'>{label}</div>}
                    <ul>
                        <li style={{ listStyle: 'none' }}>
                            <p className='tooltip-name'>{label}</p>
                            <span className='tooltip-value'>{toggleType ? payload[1].value : `${payload[1].value}%`}</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    return null;
};



//   **************Horizontal Barchart**************

export const getHorizontalGraphType = (type, toggleType) => {
    switch (type) {
        // case "overall":
        // case 'upcmHamletSurvey':
        //     return (
        //         <Bar barSize={20} minPointSize={1} dataKey="uv" background={{ fill: '#eee' }} stackId="a" fill="#8BAAFF">
        //             <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
        //         </Bar>
        //     )
            case "overall":
                return (
                  <>
                        <Bar barSize={111} dataKey="uv" stackId="a" fill="#8BAAFF" isAnimationActive={false}>
                   {/* <LabelList dataKey={toggleType ? "uv" : "pctTotal"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
                </Bar>
                        <Bar barSize={111} dataKey={toggleType ? "notAvailable" : "notAvailablePct"} stackId="a" fill="#808080" isAnimationActive={false}>
                    {/* <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' /> */}
                </Bar>
                        <Bar barSize={111} dataKey="calculatedTotal" stackId="a" fill="#d3d3d3" isAnimationActive={false}>
                    <LabelList dataKey={toggleType ? "amt" : "pctTotal"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
                </Bar>
               
                  </>
                 
                )
            case "upcmHamletSurvey":
                return (
                    <>
                     <Bar barSize={111} dataKey="uv" stackId="a" fill="#8BAAFF" >
                     <LabelList dataKey="uv" content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
                  </Bar>
                     <Bar  barSize={111} dataKey="calculatedTotal" stackId="a" fill="#d3d3d3" >
                      <LabelList dataKey={toggleType ? "amt" : "uv"} content={<RenderCustomizedLabelHorizontal toggleType={toggleType} />} position='right' />
                  </Bar>
                 
                    </>
                   
                  )
    

        //   case 'patReasonsSelect':
        //     return (
        //       <>
        //      {apiData.map((item, i) => (
        //     <Bar barSize={10} dataKey={`count${i}`} fill={colors[`count${i}`]} />
        //   ))}
        //       </>
        //     )

        default:
            break;
    }
};

const horizontalTooltipElementcontent = (payload, label, type, toggleType) =>{
    switch(type){
        case "overall":
        case "upcmHamletSurvey":
            return (
                <><div className='tooltip-heading'>{label}</div>
                {
                     toggleType ? 
                     <>
                    <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name'> Number of {type === "overall" ? "didis' baseline" : "hamlets"} completed</p>
                <span className='tooltip-value'>{payload[0]?.value}</span>
                  </li>
                  {type === 'overall' && <li style={{ listStyle: 'none' }}>
                     <p className='tooltip-name'> No. of not available didis</p>
                     <span className='tooltip-value'>{`${payload[0]?.payload?.notAvailable === null ? 0 : payload[0]?.payload?.notAvailable}`}</span>
                  </li>}
                     <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name' >Total number of {type === "overall" ? "didis" : "hamlets"}</p>
                <span className='tooltip-value'>{payload[1]?.payload?.amt}</span>
                  </li>
                  <li style={{ listStyle: 'none' }}>
                     <p className='tooltip-name'>{type === "overall" ? "Overall percentage" : "Percentage of hamlets completed"}</p>
                     <span className='tooltip-value'>{type === "overall" ? `${payload[0]?.payload?.pctTotal}%` : `${payload[0]?.payload?.pct}%`}</span>
                  </li>
                  </> 
                  : <>
                   <li style={{ listStyle: 'none' }}>
                  <p className='tooltip-name'> % of {type === "overall" ? "didis' baseline" : "hamlets"} completed</p>
                <span className='tooltip-value'>{`${payload[0].value}%`}</span>
                  </li>
                     {type === 'overall' && <li style={{ listStyle: 'none' }}>
                     <p className='tooltip-name'> % of Not Available didis</p>
                     <span className='tooltip-value'>{`${payload[0]?.payload?.notAvailablePct === null ? 0 : payload[0]?.payload?.notAvailablePct}%`}</span>
                  </li>}
                         <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                         <p className='tooltip-name' >Total number of {type === "overall" ? "didis" : "hamlets"}</p>
                         <span className='tooltip-value'>{payload[1]?.payload?.amt}</span>
                     </li>
                     <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                         <p className='tooltip-name'> {type === "overall" ? "Overall %" : " Number of hamlet completed"}</p>
                         <span className='tooltip-value'>{type === "overall" ? `${payload[0]?.payload?.pctTotal}%` : payload[0]?.payload?.count}</span>
                     </li>
                  </>
                }
                </>
            )
        case "VOReceiptFund":
        case "CSGDisbursement":
            return (
                <><div className='tooltip-heading'>{label}</div>
                {
                     toggleType ? 
                     <>
                    <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name'>{type === "VOReceiptFund" ? "Total VOs" : "Total Didis"}</p>
                <span className='tooltip-value'>{payload[0]?.payload?.amt}</span>
                  </li>
                     <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} Not Started</p>
                <span className='tooltip-value'>{payload[1]?.payload?.notStarted}</span>
                  </li>
                  <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} In progress</p>
                <span className='tooltip-value'>{payload[1]?.payload?.inProgress}</span>
                  </li>
                  {type === "CSGDisbursement" && <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name' >Not Available</p>
                <span className='tooltip-value'>{payload[1]?.payload?.notAvailable}</span>
                  </li>}
                  <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                  <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} Completed</p>
                <span className='tooltip-value'>{payload[1]?.payload?.uv}</span>
                  </li>
                  <li style={{ listStyle: 'none' }}>
                     <p className='tooltip-name'>% of task completion</p>
                     <span className='tooltip-value'>{payload[0]?.payload?.pct}%</span>
                  </li>
                  </> 
                  : <>
                  <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                <p className='tooltip-name'>{type === "VOReceiptFund" ? "Total VOs" : "Total Didis"}</p>
              <span className='tooltip-value'>{payload[0]?.payload?.amt}</span>
                </li>
                   <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} Not Started</p>
              <span className='tooltip-value'>{payload[1]?.payload?.notStarted}</span>
                </li>
                <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} In progress</p>
              <span className='tooltip-value'>{payload[1]?.payload?.inProgress}</span>
                </li>
                {type === "CSGDisbursement" && <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                    <p className='tooltip-name' >Not Available</p>
                  <span className='tooltip-value'>{payload[1]?.payload?.inProgress}</span>
                    </li>
                }
                <li style={{ listStyle: 'none', marginBottom: "7px" }}>
                <p className='tooltip-name' >{type === "VOReceiptFund" ? "VOs" : "Disbursement"} Completed</p>
              <span className='tooltip-value'>{payload[1]?.payload?.count}</span>
                </li>
                <li style={{ listStyle: 'none' }}>
                   <p className='tooltip-name'>% of task completion</p>
                   <span className='tooltip-value'>{payload[0]?.payload?.pct}%</span>
                </li>
                </> 
                }
                </>
            )
        default:
            return (
                <li style={{ listStyle: 'none' }}>
                 <p className='tooltip-name'>{label}</p>
               <span className='tooltip-value'>{toggleType ? payload[0].value : `${payload[0].value}%`}</span>
                 </li>
                   )
    }
}

export const CustomHorizontalTooltipElement = ({ active, payload, label, type, toggleType }) => {
    if (active && payload && payload.length) {
        // console.log(payload,"payload>>")
        return (
            <div id="tooltip"  className="right">
            <div className="tooltip-arrow" />
             <div className="tooltip-label">
               <ul>
              { horizontalTooltipElementcontent(payload, label, type, toggleType)}
               </ul>
             
               
             </div>
           </div>
        );
    }

    return null;
};

export const RenderCustomizedLabelHorizontal = (props) => {
    const { x, y, width, value, toggleType } = props;
    const radius = -15;
    // console.log(props, "props");

    return (
        <g>
            <text x={x + 4 + width} y={y - radius} fill="#000" textAnchor="right" >
                {toggleType ? value : `${value}%`}
            </text>
        </g>
    );
};

export const stageDropDown = [
    {
        value: 'Wealth Ranking',
        name: 'Wealth Ranking',
    },
    {
        value: 'PAT',
        name: 'PAT',
    },
    {
        value: 'VO Endorsement',
        name: 'VO Endorsement',
    },
    {
        value: 'BPM Approval',
        name: 'BPM Approval'
    }
]




const TableColumns = (type, matrixType) => {
    let colums = []
    switch (type) {
        case 'map':
            colums = [
                {
                    key: "",
                    text: `S. No.`,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                },
                {
                    key: "district",
                    text: `District`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "baseLineCompletedDidi",
                    text: `Number of didis for whom baseline is completed`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "totaldidipersentage",
                    text: `% of total across districts`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
            ]
            break;
        case 'overall':
            colums = [
                {
                    key: "",
                    text: `S. No.`,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                },
                {
                    key: "upCmName",
                    text: `UPCM`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "didiName",
                    text: `Didi Name`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "husbandName",
                    text: `Husband name`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "baseLineStatus",
                    text: `Baseline status`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "hamlet",
                    text: `Hamlet`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "villageName",
                    text: `Village`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "blockName",
                    text: `Block`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "districtName",
                    text: `District`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "sectionCount",
                    text: 'Incomplete Sections Count',
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
            ]
            break;
            case 'houseHold':
            colums = [
                {
                    key: "",
                    text: `S. No.`,
                    formatter: (cell, row, rowIndex) => rowIndex + 1,
                },
                {
                    key: "didiName",
                    text: `Didi Name`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "husbandName",
                    text: `Husband name`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                  key: "houseNo",
                  text: `House no`,
                  formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
              },
                {
                    key: "uid",
                    text: `UID`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                },
                {
                    key: "houseHoldSize",
                    text: `House hold size`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "voVillageName",
                    text: `Vo & Village`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "blockName",
                    text: `Block`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                    key: "districtName",
                    text: `District`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                {
                  key: "stateName",
                  text: `State`,
                  formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
              },

            ]
            break;
            case 'foodSecurity':
              colums = [
                  {
                      key: "",
                      text: `S. No.`,
                      formatter: (cell, row, rowIndex) => rowIndex + 1,
                  },
                  {
                      key: "didiName",
                      text: `Didi Name`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                  },
                  {
                      key: "husbandName",
                      text: `Husband name`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                  },
                  {
                    key: "houseNo",
                    text: `House no`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
                  {
                      key: "uid",
                      text: `UID`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                  },
                  {
                      key: "foodConsumption",
                      text: `${matrixType}`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                  {
                      key: "voVillageName",
                      text: `Vo & Village`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                  {
                      key: "blockName",
                      text: `Block`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                  {
                      key: "districtName",
                      text: `District`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                  {
                    key: "stateName",
                    text: `State`,
                    formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                },
  
              ]
              break;
              case 'socialInclusion':
                colums = [
                    {
                        key: "",
                        text: `S. No.`,
                        formatter: (cell, row, rowIndex) => rowIndex + 1,
                    },
                    {
                        key: "didiName",
                        text: `Didi Name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "husbandName",
                        text: `Husband name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                      key: "houseNo",
                      text: `House no`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                    {
                        key: "uid",
                        text: `UID`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "shgSavings",
                        text: `${matrixType}`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "voVillageName",
                        text: `Vo & Village`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "blockName",
                        text: `Block`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "districtName",
                        text: `District`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                      key: "stateName",
                      text: `State`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
    
                ]
                break;
                case 'financialInclusion':
                colums = [
                    {
                        key: "",
                        text: `S. No.`,
                        formatter: (cell, row, rowIndex) => rowIndex + 1,
                    },
                    {
                        key: "didiName",
                        text: `Didi Name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "husbandName",
                        text: `Husband name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                      key: "houseNo",
                      text: `House no`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                    {
                        key: "uid",
                        text: `UID`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "insuranceType",
                        text: `Insurance type`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "voVillageName",
                        text: `Vo & Village`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "blockName",
                        text: `Block`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "districtName",
                        text: `District`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                      key: "stateName",
                      text: `State`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
    
                ]
                break;

                case 'entitlementsDistribution':
                colums = [
                    {
                        key: "",
                        text: `S. No.`,
                        formatter: (cell, row, rowIndex) => rowIndex + 1,
                    },
                    {
                        key: "didiName",
                        text: `Didi Name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "husbandName",
                        text: `Husband name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                      key: "houseNo",
                      text: `House no`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                    {
                        key: "uid",
                        text: `UID`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "entitlements",
                        text: `Entitlements`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "voVillageName",
                        text: `Vo & Village`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "blockName",
                        text: `Block`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "districtName",
                        text: `District`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                      key: "stateName",
                      text: `State`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
    
                ]
                break;
                case 'livelihoodIncomeDistribution':
                colums = [
                    {
                        key: "",
                        text: `S. No.`,
                        formatter: (cell, row, rowIndex) => rowIndex + 1,
                    },
                    {
                        key: "didiName",
                        text: `Didi Name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "husbandName",
                        text: `Husband name`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                      key: "houseNo",
                      text: `House no`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
                    {
                        key: "uid",
                        text: `UID`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                    },
                    {
                        key: "livelihoodIncome",
                        text: `Total Livelihood income`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "voVillageName",
                        text: `Vo & Village`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "blockName",
                        text: `Block`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                        key: "districtName",
                        text: `District`,
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                    },
                    {
                      key: "stateName",
                      text: `State`,
                      formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                  },
    
                ]
                break;
                case 'upcmHamletSurvey':
                    colums = [
                        {
                            key: "",
                            text: `S. No.`,
                            formatter: (cell, row, rowIndex) => rowIndex + 1,
                        },
                        {
                            key: "upCmName",
                            text: `UPCM`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                        },
                        {
                            key: "hamletName",
                            text: `Hamlet name`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')
                        },
                        {
                          key: "hamletStatus",
                          text: `Hamlet status`,
                          formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                      },
                        {
                            key: "villageName",
                            text: `Vo & Village`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                            key: "blockName",
                            text: `Block`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                            key: "districtName",
                            text: `District`,
                            formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                          key: "stateName",
                          text: `State`,
                          formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                        {
                        key: "sectionCount",
                        text: 'Incomplete Sections Count',
                        formatter: (cell, row, rowIndex) => cell?.toString()?.replace('_', ' ')?.toUpperCase()
                        },
                    ]
                    break;


        default:
            colums = []
    }
    return colums;
}
export default TableColumns;
// export const matrixDrowdown = {
//   map:[
//     {
//         value: "No. of Ultra-Poor",
//         name: "Ultra-Poor identified in PAT"
//     },
//     {
//         value: "No. of CRPs",
//         name: "No. of CRPs"
//     },
//     {
//         value: "Sent For VO Endorsement",
//         name: "Ultra-Poor endorsed by VO"
//     },
//     {
//         value: "BPM Approval Completed",
//         name: "Ultra-Poor approved by BPM"
//     },
//     {
//         value: "PAT Mismatch %",
//         name: "PAT Mismatch %"
//     },
// ],
// houseHold:[
//   {
//       value: "HouseholdSize",
//       name: "Household size"
//   },
//   {
//       value: "SocialCategory",
//       name: "Social category"
//   },
//   {
//       value: "ChildInAngwanwadi",
//       name: "Child in Angwanwadi"
//   },
//   {
//       value: "ChildImmunized",
//       name: "Child immunized"
//   },
//   {
//       value: "ChildGoingToSchool",
//       name: "Child going to school"
//   },
// ],
// foodSecurity:[
//   {
//       value: "twoMealsPerDay",
//       name: "2 meals per day for the last 1 month"
//   },
//   {
//       value: "kitchenGarden",
//       name: "Kitchen garden"
//   },
//   {
//       value: "foodConsumption",
//       name: "Food consumption"
//   },
//   {
//       value: "typeOfRationCard",
//       name: "Type of ration card"
//   }
// ],
// socialInclusion:[
//     {
//         value: "shgMembership",
//         name: "SHG membership"
//     },
//     {
//         value: "shgAttendance",
//         name: "SHG attendance"
//     },
//     {
//         value: "savingsInSHG",
//         name: "Savings in SHG"
//     },
//     {
//         value: "LoanFromSHG",
//         name: "Loan from SHG"
//     }
//   ],
// financialInclusion:[
//   {
//       value: "bankAccount",
//       name: "Bank account"
//   },
//   {
//       value: "debt",
//       name: "Debt"
//   },
//   {
//       value: "insurance",
//       name: "Insurance"
//   },
//   {
//       value: "savings",
//       name: "Savings"
//   }
// ],
// }

  export const getHeaders = (type) => {

    let heading = [];
  
    if (type === 'map') {
      heading = [
        "District",
        "Number of didis for whom baseline is completed",
        "% of total across districts",
      ];
    } else if (type === 'overall') {
      heading = [
        "UPCM",
        "Didi",
        "Husband name",
        "Baseline status",
        "Hamlet",
        "Village",
        "Block",
        "District",
      ];
    } 
  return heading;
}

export const graphTransformer = (data, type, showData, stageType, toggleType, question, summary) => {
    let graphData = [];
    // console.log("data",data)
    if (data) {
      if (type === "overall") {
       const totalData={
            name: "Total",
            uv: data?.totalCompletedBaselineDidiCount,
            pv: 0,
            amt: data?.totalUpCmDidiCount,
            pct: data?.totalUpCmDidiPercentage,
        }
        // const sortedData = data?.summaryBaseLineDTOS === null ? data?.summaryBaseLineDTOS : sortByKey(data?.summaryBaseLineDTOS, 'percentageUpCm', false )
        data?.summaryBaseLineDTOS?.map((item)=>{
            let newData={}
            newData = {
                name: item.upCmName,
                uv: toggleType ? item?.baseLineUpCmCompleted : Math.trunc(item?.completedPercentage),
                pv: 0,
                amt: item?.totalDidiUpCm,
                pct: Math.trunc(item?.completedPercentage),
                calculatedTotal: toggleType ? item?.totalDidiUpCm - item?.baseLineUpCmCompleted - item?.notAvailableBeneficiaryCount : 100 - item?.completedPercentage - item?.notAvailablePercentage, 
                count: item?.baseLineUpCmCompleted,
                notAvailable: item?.notAvailableBeneficiaryCount,
                notAvailablePct: item?.notAvailablePercentage,
                pctTotal: Math.trunc(item?.percentageUpCm),
            }
            graphData.push(newData)
        })
        showData && graphData.push(totalData)

      }
      else if (type === "houseHold") {
        graphData = [
            {
                name: 2,
                uv: toggleType ? data?.firstNo : data?.firstPer,
                pv: 0,
                amt: data?.firstNo,
                pct: data?.firstPer,
              },
              {
                name: 3,
                uv: toggleType ? data?.secondNo : data?.secondPer,
                pv: 0,
                amt: data?.secondNo,
                pct: data?.secondPer,
              },
              {
                name: 5,
                uv: toggleType ? data?.thirdNo : data?.thirdPer,
                pv: 0,
                amt: data?.thirdNo,
                pct: data?.thirdPer,
              },
              {
                name: 6,
                uv: toggleType ? data?.fourthNo : data?.fourthPer,
                pv: 0,
                amt: data?.fourthNo,
                pct: data?.fourthPer,
              },
              {
                name: 7,
                uv: toggleType ? data?.fifthNo : data?.fifthPer,
                pv: 0,
                amt: data?.fifthNo,
                pct: data?.fifthPer,
              },
              {
                name: 8,
                uv: toggleType ? data?.sixthNo : data?.sixthPer,
                pv: 0,
                amt: data?.sixthNo,
                pct: data?.sixthPer,
              },
              {
                name: 9,
                uv: toggleType ? data?.eighthNo : data?.eighthPer,
                pv: 0,
                amt: data?.eighthNo,
                pct: data?.eighthPer,
              },
              {
                name: 4,
                uv: toggleType ? data?.ninthNo : data?.ninthPer,
                pv: 0,
                amt: data?.ninthNo,
                pct: data?.ninthPer,
              },
        ];
      } else if (type === "foodSecurity") {
        graphData = data?.map(item => ({
            name: item?.value,
            uv: toggleType ? item?.count : item?.percentage,
            pv: 0,
            amt: item?.count,
            pct: item?.percentage,
          }))
      }
      else if (type === "socialInclusion") {
        graphData = data?.map(item => ({
            name: item?.value,
            uv: toggleType ? item?.count : item?.percentage,
            pv: 0,
            amt: item?.count,
            pct: item?.percentage,
          }))
      } else if (type === "financialInclusion") {
        graphData = [
            {
                name: "Health",
                uv: toggleType ? data?.healthNo : data?.healthPer,
                pv: 0,
                amt: data?.healthNo,
                pct: data?.healthPer,
              },
              {
                name: "Life",
                uv: toggleType ? data?.lifeNo : data?.lifePer,
                pv: 0,
                amt: data?.lifeNo,
                pct: data?.lifePer,
              },
              {
                name: "Accident",
                uv: toggleType ? data?.accidentNo : data?.accidentPer,
                pv: 0,
                amt: data?.accidentNo,
                pct: data?.accidentPer,
              },
              {
                name: "Crop",
                uv: toggleType ? data?.cropNo : data?.cropPer,
                pv: 0,
                amt: data?.cropNo,
                pct: data?.cropPer,
              },
              {
                name: "Other",
                uv: toggleType ? data?.otherNo : data?.otherPer,
                pv: 0,
                amt: data?.otherNo,
                pct: data?.otherPer,
              },
              {
                name: "None",
                uv: toggleType ? data?.noneNo : data?.nonePer,
                pv: 0,
                amt: data?.noneNo,
                pct: data?.nonePer,
              },
              
        ];
      }  else if (type === "entitlementsDistribution") {
        graphData = [
            {
                name: "PVTG pension",
                uv: toggleType ? data?.pvtgPensionNo : data?.pvtgPensionPer,
                pv: 0,
                amt: data?.pvtgPensionNo,
                pct: data?.pvtgPensionPer,
              },
              {
                name: "Ujjwala",
                uv: toggleType ? data?.ujjwalaNo : data?.ujjwalaPer,
                pv: 0,
                amt: data?.ujjwalaNo,
                pct: data?.ujjwalaPer,
              },
              {
                name: "Awas Yojana",
                uv: toggleType ? data?.awasYojanaNo : data?.awasYojanaPer,
                pv: 0,
                amt: data?.awasYojanaNo,
                pct: data?.awasYojanaPer,
              },
              {
                name: "Jal Jeevan Mission",
                uv: toggleType ? data?.jalJeevanMissionNo : data?.jalJeevanMissionPer,
                pv: 0,
                amt: data?.jalJeevanMissionNo,
                pct: data?.jalJeevanMissionPer,
              },
              {
                name: "Other",
                uv: toggleType ? data?.otherNo : data?.otherPer,
                pv: 0,
                amt: data?.otherNo,
                pct: data?.otherPer,
              },
              {
                name: "None",
                uv: toggleType ? data?.noneNo : data?.nonePer,
                pv: 0,
                amt: data?.noneNo,
                pct: data?.nonePer,
              },
              
        ];
      } else if (type === "livelihoodIncomeDistribution") {
        graphData = [
            {
                name: "1500-2000",
                uv: toggleType ? data?.fifteenHundredToTwentyHundredNo : data?.fifteenHundredToTwentyHundredPer,
                pv: 0,
                amt: data?.fifteenHundredToTwentyHundredNo,
                pct: data?.fifteenHundredToTwentyHundredPer,
              },
              {
                name: "2000-2230",
                uv: toggleType ? data?.twentyHundredToTwentyTwoHundredThirtyNo : data?.twentyHundredToTwentyTwoHundredThirtyPer,
                pv: 0,
                amt: data?.twentyHundredToTwentyTwoHundredThirtyNo,
                pct: data?.twentyHundredToTwentyTwoHundredThirtyPer,
              },
              {
                name: "2230-2500",
                uv: toggleType ? data?.twentyTwoHundredThirtyToTwentyFiveHundredNo : data?.twentyTwoHundredThirtyToTwentyFiveHundredPer,
                pv: 0,
                amt: data?.twentyTwoHundredThirtyToTwentyFiveHundredNo,
                pct: data?.twentyTwoHundredThirtyToTwentyFiveHundredPer,
              },
              {
                name: "2500-3000",
                uv: toggleType ? data?.twentyFiveHundredToThirtyHundredNo : data?.twentyFiveHundredToThirtyHundredPer,
                pv: 0,
                amt: data?.twentyFiveHundredToThirtyHundredNo,
                pct: data?.twentyFiveHundredToThirtyHundredPer,
              }
              
        ];
    }
        else if(type === "upcmHamletSurvey") {
    
            const totalData={
                name: "Total",
                uv: data?.totalCompleteHamletCount,
                pv: 0,
                amt: data?.totalHamletCount,
                pct: data?.totalHamletPercentage,
            }
            // const sortedData = data?.hamletSummaryDTO === null ? data?.hamletSummaryDTO : sortByKey(data?.hamletSummaryDTO, 'percentage', false )
            data?.hamletSummaryDTO?.map((item)=>{
                let newData={}
                newData = {
                    name: item.upCmName,
                    uv: toggleType ? item?.hamletCompleted : Math.trunc(item?.percentage),
                    pv: 0,
                    amt: item?.hamletTotalCount,
                    pct: Math.trunc(item?.percentage),
                    calculatedTotal: toggleType ? item?.hamletTotalCount - item?.hamletCompleted : 100 - item?.percentage,
                    count: item?.hamletCompleted
                }
                graphData.push(newData)

            })
           showData && graphData.push(totalData)
      }
    }

    return { graphData };
  };

  export const summaryTransformer = (type, data, mapType, role, stageType, toggleType, question, totalSummaryData, summaryMapValue) => {
    let heading = [];
    let summaryValues = [];
    // console.log(graphTransformer(data, type)?.graphData, "fttgttg");
    const checkEmptyValues = graphTransformer(data, type)?.graphData?.every(item => item.uv === 0);
    if (type === "map") {
      heading = [getHeading(role), mapType === 'PAT Mismatch %' ? 'BPC PAT Verified Didis' : mapType, getPercentageHeading(mapType)];
      summaryValues =
        Array.isArray(data) &&
        data?.map((summaryvalue, ind) => (
          <tr key={`${summaryvalue?.name}${ind}`}>
            <td>{summaryvalue?.name}</td>
            <td>{getMapDataTypeValue(summaryvalue, mapType)?.numericalValue}</td>
            <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td>
          </tr>
        ));
  
      if (mapType === "No. of Ultra-Poor") {
        summaryValues.push(<tr>
          <td>Total</td>
          <td>{summaryMapValue?.ultraPoorCount}</td>
          <td>{summaryMapValue?.totalUltraPoorPercentage}</td>
        </tr>)
  
      } else if (mapType === "No. of CRPs") {
        summaryValues.push(<tr>
          <td>Total</td>
          <td>{summaryMapValue?.crpCount}</td>
          <td>{summaryMapValue?.totalCrpPercentage}</td>
        </tr>)
      } else if (mapType === "Sent For VO Endorsement") {
        summaryValues.push(<tr>
          <td>Total</td>
          <td>{summaryMapValue?.voEndrosmentCount}</td>
          <td>{summaryMapValue?.totalVoPercentage}</td>
        </tr>)
      } else if (mapType === "BPM Approval Completed") {
        summaryValues.push(<tr>
          <td>Total</td>
          <td>{summaryMapValue?.bpmApprovalCount}</td>
          <td>{summaryMapValue?.totalBpmApprovedPercentage}</td>
        </tr>)
      }
  
    } else if (type === "overall") {
        heading = ['UPCM', "No. of didis' baseline completed",'No. of not available didis','Total number of didis', 'Percentage (completed + not available)'];
        // console.log(data,"data>>>")
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'overall', true, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue?.name}</td>
            <td>{summaryvalue?.uv}</td>
            <td>{summaryvalue?.notAvailable}</td>
            <td>{summaryvalue?.amt}</td>
            <td>{summaryvalue?.pctTotal}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "houseHold") {
        heading = ['Household Size', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'houseHold', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "foodSecurity") {
        heading = ['Food consumption', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'foodSecurity', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "socialInclusion") {
        heading = ['Savings (by percentile)', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'socialInclusion', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "financialInclusion") {
        heading = ['Savings (by percentile)', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'financialInclusion', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "entitlementsDistribution") {
        heading = ['Entitlement', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'entitlementsDistribution', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    } else if (type === "livelihoodIncomeDistribution") {
        heading = ['Insurance type', 'Number of didis', '% of didis'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'livelihoodIncomeDistribution', false, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue.name}</td>
            <td>{summaryvalue.amt}</td>
            <td>{summaryvalue.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    }
    else if (type === "upcmHamletSurvey") {
        heading = ['UPCM', 'No. of hamlets completed', 'Total no. of hamlets', 'Percentage (completed)'];
        summaryValues = Object.keys(data)?.length > 0 && !checkEmptyValues ? graphTransformer(data, 'upcmHamletSurvey', true, '', true)?.graphData?.map(summaryvalue => (
          <tr>
            <td>{summaryvalue?.name}</td>
            <td>{summaryvalue?.uv}</td>
            <td>{summaryvalue?.amt}</td>
            <td>{summaryvalue?.pct}</td>
    
            {/* <td>{getMapDataTypeValue(summaryvalue, mapType)?.percentValue}</td> */}
          </tr>
        )) : []
    }

    return {
        heading,
        summaryValues,
      };
    };

    export const getHeading = (type) => {
        let obj = {
          National: "States",
          State: "Districts",
          District: "Blocks",
          Block: "Villages",
        };
      
        return obj[type];
      };
      export const getPercentageHeading = (type) => {
        let obj = {
          "No. of Ultra-Poor": "% of Ultra-Poor",
          "No. of CRPs": "% of CRPs",
          "Sent For VO Endorsement": "% of VO Endorsement",
          "BPM Approval Completed": "% of BPM Approval",
          "PAT Mismatch %": "PAT Mismatch %",
        };
      
        return obj[type];
      };
      export const getMapDataTypeValue = (value, type) => {
        let typeOfData = {};
        if (type === "No. of Ultra-Poor") {
          typeOfData.numericalValue = value?.ultraPoorCount;
          typeOfData.percentValue = value?.ultraPoorPercentage;
        } else if (type === "No. of CRPs") {
          typeOfData.numericalValue = value?.crpCount;
          typeOfData.percentValue = value?.crpCountPercentage;
        } else if (type === "Sent For VO Endorsement") {
          typeOfData.numericalValue = value?.voEndrosmentCount;
          typeOfData.percentValue = value?.voEndrosmentPercentage;
        } else if (type === "BPM Approval Completed") {
          typeOfData.numericalValue = value?.bpmApprovalCount;
          typeOfData.percentValue = value?.bpmApprovalPercentage;
        } else if (type === "PAT Mismatch %") {
          typeOfData.numericalValue = value?.bpcPatCount;
          typeOfData.percentValue = value?.patMismatchPercentage;
        }
      
        return typeOfData;
      };

export const getResponse = {
    National: "states",
    State: "districts",
    District: "blocks",
    Block: "villages",
};

export const getBaselineDiagramViewApiPath = (type) => {
    let apiPaths = {
        overall: `${GlobalConfig.API_ROOT}/survey-service/baseline-web/upcm/view`,
        upcmHamletSurvey: `${GlobalConfig.API_ROOT}/survey-service/hamlet-web/view`,
        foodSecurity: `${GlobalConfig.API_ROOT}/didi-service/graph/food-security/view`,
        socialInclusion: `${GlobalConfig.API_ROOT}/didi-service/graph/social-inclusion/view`,
    };
    return apiPaths[type];
}

export const getBaselineSourceDataApiPath = (type, payload) => {
    let apiPaths = {
        overall: `${GlobalConfig.API_ROOT}/survey-service/baseline-web/source-data`,
        upcmHamletSurvey: `${GlobalConfig.API_ROOT}/survey-service/hamlet-web/source-data`,
        foodSecurity: `${GlobalConfig.API_ROOT}/didi-service/source/food-security/data`,
        socialInclusion: `${GlobalConfig.API_ROOT}/didi-service/source/social-inclusion/data`,
    };
    return apiPaths[type];
}

export const matrixDrowdown = (type) => {
    let apiPath = {
        foodSecurity: `${GlobalConfig.API_ROOT}/didi-service/tags/food-security`,
        socialInclusion: `${GlobalConfig.API_ROOT}/didi-service/tags/social-inclusion`
    }
    return apiPath[type]
}

export const getSourceDataExportToCsvPath = (type, referenceId, referenceName, tagId, tagName) => {
    let apiPaths = {
        overall: `${GlobalConfig.API_ROOT}/survey-service/baseline-web/source-data/download?referenceId=${referenceId}&referenceName=${referenceName}`,
        upcmHamletSurvey: `${GlobalConfig.API_ROOT}/survey-service/hamlet-web/source-data/download?referenceId=${referenceId}&referenceName=${referenceName}`,
        foodSecurity: `${GlobalConfig.API_ROOT}/didi-service/source/food-security/download?referenceId=${referenceId}&referenceName=${referenceName}&tagId=${tagId}&tageName=${tagName}`,
        socialInclusion: `${GlobalConfig.API_ROOT}/didi-service/source/download-social-inclusion/data?referenceId=${referenceId}&referenceName=${referenceName}&tagId=${tagId}&tagName=${tagName}`,
    };
    return apiPaths[type];
}
export const getGrantSourceDataExportToCsvPath = (type, referenceId, referenceName) => {
    let apiPaths = {
        VOReceiptFund: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/download/fund-source-data?referenceId=${referenceId}&referenceType=${referenceName}&subjectName="Fund_VO_SourceData"`,
        CSGDisbursement: `${GlobalConfig.API_ROOT}/mission-service/csg-dashboard/download/dis-source-data?referenceId=${referenceId}&referenceType=${referenceName}&subjectName="CSG_Disbursement_SourceData"`,
    };
    return apiPaths[type];
}