import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { catchErrorHandler } from '../../../services/commonUtils';
import { setActiveRegenerate, setApiError, showLoaderAction } from '../../../storeManager/common/actions'
import { Collapse } from 'reactstrap';
import arrow from "../../../assets/images/arrow.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import './style.css'
import { commonService } from '../../../services/common';
import { getCurrentUser, isSuperAdmin, isUserManager } from '../../../services/commonUtils';
import { isAuthenticated } from '../../../services/commonUtils';
import logoSrc from '../../../assets/images/SarathiLogoFull.svg'
import { userRoles as roles } from '../../../config/roles';
import ToastMsg from '../ToastMsg';
import GlobalConfig from "../../../config/globalconfig"

function Header() {
  const dispatch = useDispatch();
  const { taskRegenerateActiveStatus } = useSelector(state => state.commonReducer)

  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [generateIsSuccess, setGenerateIsSuccess] = useState(false);
 
  const navigate = useNavigate();

  const toggle = () => setIsOpen(!isOpen);
  const userData = JSON.parse(localStorage.getItem('userAuth')) || ''
  const { name, roleName, typeName } = userData

  const  missionData = JSON.parse(sessionStorage.getItem("missions")) || []
  

  useEffect(() => {

    const handleRefresh = () => {
      sessionStorage.removeItem('missions');
    };
    window.addEventListener('beforeunload', handleRefresh);

    if(!missionData?.length > 0) {
      getMissionList()
    } 

    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };

  }, [])

  const getMissionList = async () => {
    dispatch(showLoaderAction(true));
    try {
      const res = await commonService.fetchMissionList();
      const resObj = res.data;
      if (resObj?.message === "SUCCESS" && resObj?.data?.length > 0) {
        const _data = transformMissionData(resObj.data)
        sessionStorage.setItem("missions",  JSON.stringify(_data))
      }
      dispatch(showLoaderAction(false));
    } catch (error) {
      dispatch(showLoaderAction(false));
      catchErrorHandler(error, dispatch(setApiError()));
    }
  }

  const transformMissionData = (data) => {
    const _missionData = data.map(_mission => {
      let link = `/generic-dashboard/${_mission.id}`
      const type = (_mission?.type)?.toUpperCase()
      switch (type) {
        case "SELECTION": link = '/dashboard'; break;
        case "BASELINE": link = '/baseline-dashboard'; break;
        case "CSG": link = '/grant-dashboard'; break;
        default: link = `/generic-dashboard/${_mission.id}`
      }
      return {
        ..._mission,
        link: link
      }
    })
    return _missionData
  }

  const logout = async () => {
    try {
      await commonService.logOutUser()
      localStorage.removeItem('userAuth');
      localStorage.removeItem('token')
      localStorage.removeItem('phoneNumberData')
      sessionStorage.removeItem('missions')
      navigate('/')
    } catch (error) {
      console.error(error)
    }
  }
  const url = window.location.pathname;
  const currentUserRole = getCurrentUser();

  const handleGenerateTask = async () => {
    setToastMessage("")
    setGenerateIsSuccess(false)
    const payload = {
        "missionIds": taskRegenerateActiveStatus?.missionId,
        "programId": taskRegenerateActiveStatus?.programId
    }
    try {
        const response = await commonService.regenerateTaskStatus(payload);
        const res = response.data;
        if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
          setToastMessage(res?.data)
          setGenerateIsSuccess(true)
          dispatch(setActiveRegenerate({ value: false, missionId: null, programId: null }));
        } else {
          dispatch(setActiveRegenerate({ value: false, missionId: null, programId: null }));
        }
    } catch (error) {
        console.error(error)
        catchErrorHandler(error, dispatch(setApiError()));
        dispatch(setActiveRegenerate({ value: false, missionId: null, programId: null }));
    }
};
// useEffect(()=>{
//   if(taskRegenerateActiveStatus?.value){
//     handleGenerateTask()
//   }
// },[taskRegenerateActiveStatus.value])

  return (
    <header className="header">
      {generateIsSuccess && <ToastMsg success  otpMsg={toastMessage} />}
      <img className='logo-img' src={logoSrc} alt="Sarthi" />
      {isAuthenticated() && <>
        <div className="navigation">
          <ul>
            {!isUserManager() ? 
            isSuperAdmin() ? 
            <li><NavLink to="/super-admin">Super Admin</NavLink></li>
            :
              <><li className="dashboard-dropdown">
                <Link to="." className={url.includes('dashboard') ? 'active' : ''}>Missions<img className='down-arrow' src={arrow} alt="logo" /></Link>
                  <ul className='sub-menu'>
                    {missionData.length > 0 && missionData.map((_mission, index) => {
                      return <li><NavLink to={{ pathname: _mission.link, state: { id: _mission } }}>{index + 1}. {_mission.name}</NavLink></li>
                    })}
                </ul>
              </li>
                {roleName === 'Block' && typeName === 'Project Manager' && <li><NavLink to="/bpm-approval">Approvals</NavLink></li>}
                {[roles?.BPC, roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM
                ].includes(currentUserRole) && <li><NavLink to="/bpc-approval">Approvals</NavLink></li>}
                <li><NavLink to="/beneficiaries">Beneficiaries</NavLink></li>
                <li><NavLink to="/livelihood-data">Livelihood</NavLink></li>
                {/* <li><NavLink to="/downloads-flow">Downloads</NavLink></li> */}
              </>
              :
                <>
                <li><NavLink to="/user-management">User Management</NavLink></li>
                <li><NavLink to="/village-management">Village Management</NavLink></li>
                <li><NavLink to="/backup-file-upload">Backup File Upload</NavLink></li>
                </>
             
            }
            {/*<li><NavLink to="/questionaire">Questionnaire</NavLink></li> */}

          </ul>
        </div>
        <div className="user-profile">
          <div className='user-icon'></div>
          <div className='user-name' onClick={toggle}>
            <span className='uname'>{name}</span>
            <span><img src={arrow} alt="logo" /></span>

          </div>

        </div>
      </>}
      <Collapse isOpen={isOpen} className="collapse-toggle" >
        <div>
          <ul>
            <li onClick={() => navigate('/profile')}>Profile</li>
            <li onClick={() => logout()}>Logout</li>
          </ul>
        </div>
      </Collapse>

    </header>
  );
}

export default Header;
